html,
body {
    height: 100%;
    min-height: 100%;
}

body {
    background: url('/img/denkmalneu_logo_bg.png') no-repeat fixed bottom right;
}

a:focus { outline: none; }

.ui.segment.navigation,
.ui.menu {
    border-radius: 0;
}

/*  spezielles "active" für aktive Menüpunkte mit Dropdown/Submenu
    Sementic UI deaktiviert via JS hover bei bei normalen .active-Elementen */
.ui.inverted.menu .active-hover.item,
.ui.inverted.menu > .item:hover {
    color: #fff !important;
    background: -moz-linear-gradient(top, #becd00 2px, rgba(255, 255, 255, 0.15) 2px) !important;
    background: -webkit-linear-gradient(top, #becd00 2px, rgba(255, 255, 255, 0.15) 2px) !important;
    background: linear-gradient(to bottom, #becd00 2px, rgba(255, 255, 255, 0.15) 2px) !important;
}

.ui.inverted.menu > .item.vc-active {
    color: #fff !important;
    background: -moz-linear-gradient(top, #fff 2px, #ff851b 2px) !important;
    background: -webkit-linear-gradient(top, #fff 2px, #ff851b 2px) !important;
    background: linear-gradient(to bottom, #fff 2px, #ff851b 2px) !important;
}

/* responsive navigation */
.ui.navigation.list a.active-hover.item,
.ui.navigation.list a.item:hover {
    color: #4183c4 !important;
}

.ui.menu .active-hover.item {
    background: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.95);
}

.ui.menu .item.right::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background: rgba(34, 36, 38, 0.1);
}

.ui.menu img.home { width: 1.5em !important; }

.ui.segments.filter .inline.fields { margin-bottom: 0; }
.ui.horizontal.charts.segments > .segment { min-width: unset; }

.ui.menu div.gravatar-holder {
    padding-top: 0;
    padding-bottom: 0;
}

/* dashboard/charts */
.ui.clearing.charts.segment {
    padding : 0;
    border: 0;
    box-shadow: none;
}

.ui.clearing.charts.segment .ui.floated.segment { margin-top: 0; }

/* dataTables */
.ui.table thead th.sorting,
.ui.table thead th.sorting_asc,
.ui.table thead th.sorting_desc {
    cursor: pointer;
}

.ui.striped.table tbody td.highlight,
.ui.striped.table tbody tr.highlight { background-color: #becd00; }

.dataTable tr.detail-row > td.detail-row {
    background-color: rgba(0, 74, 153, 0.7);
    padding-bottom: 0.6em;
}

.ui.table td.detail-table-connector {
    background-color: rgba(0, 74, 153, 0.7);
    height: 0.5em;
    padding: 0;
    border: none;
}

.ui.table td.toggle-checkbox { padding: 0.5em 0.6em 0 0.6em; }

table.detailRows tr.has_detail > td:not(.unbound) { cursor: pointer; }
table.detailRows tr.early_access > td:first-of-type {
    background-color: rgba(0, 74, 153, 0.7);
    color: #fff;
}

.override .override-white { color: #fff !important; }

/* range-slider in fields mittig */
.ui.form .fields .ui.range .inner { margin-top: 10px; }

.container { padding: 0 10px 10px 10px; }

/**
 * don't use "!important" here!
 * show() and hide() won't work on this elements
 */
.hidden { display: none; }

/* Tools */
iframe#shell,
div.shell {
    border: none;
    background-color: #000;
    color: #fff;
    width: 100%;
    height: 50vw;
    overflow-y: scroll;
}

button.csv-export { margin-left: 1em !important; }

/* property selection */
.ui.object.cards .active.card .header,
.ui.object.cards .active.card .meta,
.ui.object.cards .active.card .description { color: #fff; }

/* property info box */
.accordion.property { margin: 1rem 0; }

.accordion.property div.flexbox {
    display: flex;
    align-items: flex-start;
}

.accordion.property div.flexbox img {
    height: 100px;
    border-radius: 5px;
    margin-right: 15px;
}

/* forms */
#contact_form .required.field:not(.error) input,
#investor_form .required.field:not(.error) input { background-color: #becd0020; }
.file-text-field { cursor: pointer; }

.ui.toggle.red-green.checkbox label { padding-top: 0; }
.ui.toggle.red-green.checkbox label::before { background-color: #dd4b39; }
.ui.toggle.red-green.checkbox input:checked ~ label::before { background-color: #00a65a !important; }

/* dropdown in datatable */
.dataTable td .ui.fluid.dropdown { width: auto; }
.dataTable td .ui.selection.dropdown { min-height: 2.15em; }

/* dropzone */
.dropzone {
    border: none;
    min-height: inherit;
    padding: 0;
}

.dropzone.dz-drag-hover { border: 1px dashed #d2d6de; }
.dropzone .dz-message { margin: 1em 0; }

/* property ribbon */
.ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 90px;
    height: 90px;
    text-align: right;
}

.ribbon span {
    font-size: 12px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 120px;
    display: block;
    background: #9d9fa5;
    background: linear-gradient(#9d9fa5 0%, #9d9fa5 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 25px;
    right: -25px;
}

.ribbon span::before {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    z-index: -1;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 3px solid #9d9fa5;
    border-top: 3px solid #9d9fa5;
}

.ribbon span::after {
    content: "";
    position: absolute;
    right: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-right: 3px solid #9d9fa5;
    border-top: 3px solid #9d9fa5;
}

.ribbon-blue span {
    background: #3c8dbc;
    background: linear-gradient(#3c8dbc 0%, #3c8dbc 100%);
}

.ribbon-blue span::before {
    border-left: 3px solid #3c8dbc;
    border-top: 3px solid #3c8dbc;
}

.ribbon-blue span::after {
    border-right: 3px solid #3c8dbc;
    border-top: 3px solid #3c8dbc;
}

.ribbon-red span {
    background: #dd4b39;
    background: linear-gradient(#dd4b39 0%, #dd4b39 100%);
}

.ribbon-red span::before {
    border-left: 3px solid #dd4b39;
    border-top: 3px solid #dd4b39;
}

.ribbon-red span::after {
    border-right: 3px solid #dd4b39;
    border-top: 3px solid #dd4b39;
}

/* Responsive */
@media only screen and (max-width: 767px) {
    .ui.segments.filter .inline.fields .field { margin-bottom: 5px; }
    .ui.table:not(.unstackable) td:first-child { font-weight: normal; }
}
