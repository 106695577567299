/*
 * General: Miscellaneous
 * ----------------------
 */
.bg-red,
.bg-yellow,
.bg-aqua,
.bg-blue,
.bg-light-blue,
.bg-green,
.bg-navy,
.bg-teal,
.bg-olive,
.bg-lime,
.bg-orange,
.bg-fuchsia,
.bg-purple,
.bg-maroon,
.bg-black,
.bg-dark-gray,
.bg-dmn-blue,
.bg-dmn-green,
.bg-red-active,
.bg-yellow-active,
.bg-aqua-active,
.bg-blue-active,
.bg-light-blue-active,
.bg-green-active,
.bg-navy-active,
.bg-teal-active,
.bg-olive-active,
.bg-lime-active,
.bg-orange-active,
.bg-fuchsia-active,
.bg-purple-active,
.bg-maroon-active,
.bg-black-active,
.bg-dmn-blue-active,
.bg-dmn-green-active {
  color: #fff !important;
}

.bg-gray {
  color: #000;
  background-color: #d2d6de !important;
}

.bg-light-gray {
  color: #000;
  background-color: #e4e8f0 !important;
}

.bg-dark-gray {
  background-color: #9d9fa5 !important;
}

.bg-black {
  background-color: #111 !important;
}

.bg-red {
  background-color: #dd4b39 !important;
}

.bg-yellow {
  background-color: #f39c12 !important;
}

.bg-aqua {
  background-color: #00c0ef !important;
}

.bg-blue {
  background-color: #0073b7 !important;
}

.bg-light-blue {
  background-color: #3c8dbc !important;
}

.bg-green {
  background-color: #00a65a !important;
}

.bg-navy {
  background-color: #001f3f !important;
}

.bg-teal {
  background-color: #39cccc !important;
}

.bg-olive {
  background-color: #3d9970 !important;
}

.bg-lime {
  background-color: #01ff70 !important;
}

.bg-orange {
  background-color: #ff851b !important;
}

.bg-fuchsia {
  background-color: #f012be !important;
}

.bg-purple {
  background-color: #605ca8 !important;
}

.bg-maroon {
  background-color: #d81b60 !important;
}

.bg-dmn-green {
  background-color: #becd00 !important;
}

.bg-dmn-blue {
  background-color: #004a99 !important;
}

.bg-gray-active {
  color: #000;
  background-color: #b5bbc8 !important;
}

.bg-light-gray-active {
  color: #000;
  background-color: #c3ccde !important;
}

.bg-dark-gray-active {
  color: #000;
  background-color: #82858d !important;
}

.bg-black-active {
  background-color: black !important;
}

.bg-red-active {
  background-color: #d33724 !important;
}

.bg-yellow-active {
  background-color: #db8b0b !important;
}

.bg-aqua-active {
  background-color: #00a7d0 !important;
}

.bg-blue-active {
  background-color: #005384 !important;
}

.bg-light-blue-active {
  background-color: #357ca5 !important;
}

.bg-green-active {
  background-color: #008d4c !important;
}

.bg-navy-active {
  background-color: #001a35 !important;
}

.bg-teal-active {
  background-color: #30bbbb !important;
}

.bg-olive-active {
  background-color: #368763 !important;
}

.bg-lime-active {
  background-color: #00e765 !important;
}

.bg-orange-active {
  background-color: #ff7702 !important;
}

.bg-fuchsia-active {
  background-color: #db0ead !important;
}

.bg-purple-active {
  background-color: #555299 !important;
}

.bg-maroon-active {
  background-color: #ca195a !important;
}

.bg-dmn-green-active {
  background-color: #b0be00 !important;
}

.text-red {
  color: #dd4b39 !important;
}

.text-yellow {
  color: #f39c12 !important;
}

.text-aqua {
  color: #00c0ef !important;
}

.text-blue {
  color: #0073b7 !important;
}

.text-black {
  color: #111 !important;
}

.text-light-blue {
  color: #3c8dbc !important;
}

.text-green {
  color: #00a65a !important;
}

.text-gray {
  color: #d2d6de !important;
}

.text-light-gray {
  color: #e4e8f0 !important;
}

.text-dark-gray {
  color: #9d9fa5 !important;
}

.text-navy {
  color: #001f3f !important;
}

.text-teal {
  color: #39cccc !important;
}

.text-olive {
  color: #3d9970 !important;
}

.text-lime {
  color: #01ff70 !important;
}

.text-orange {
  color: #ff851b !important;
}

.text-fuchsia {
  color: #f012be !important;
}

.text-purple {
  color: #605ca8 !important;
}

.text-maroon {
  color: #d81b60 !important;
}

.text-dmn-green {
  color: #becd00 !important;
}

/*# sourceMappingURL=app.css.map */
